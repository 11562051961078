export default {
    init() {
        // JavaScript to be fired on all pages
        this.formSwitcher();
    },
    formSwitcher() {
        $('a.forgot-password').click((e) => {
            e.preventDefault();
            $('.form-login').hide();
            $('.form-reset-password').show();
        });

        $('a.back-to-login').click((e) => {
            e.preventDefault();
            $('.form-login').show();
            $('.form-reset-password').hide();
        });
    },
};
