export default {
    init() {
        // JavaScript to be fired on all pages0
        this.showFlash();
    },
    showFlash() {
        $.each($('.flash-messages'), (index, value) => {
            $.notify({
                message: $(value).data('message'),
            }, {
                type: $(value).data('type'),
                mouse_over: 'pause',
                delay: $(value)[0].hasAttribute('data-delay') ? $(value).data('delay') : 5000,
                placement: {
                    from: 'top',
                    align: 'center',
                },
                animate: {
                    enter: 'animated bounceInDown',
                    exit: 'animated bounceOutUp',
                },
            });
        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
