import camelcase from 'camelcase';

class Router {

    constructor(routes) {
        this.routes = routes;
    }

    fire(route, event = 'init', arg) {
        document.dispatchEvent(new CustomEvent('routed', {
            bubbles: true,
            detail: {
                route,
                fn: event,
            },
        }));

        const fire = route !== '' && this.routes[route] && typeof this.routes[route][event] === 'function';
        if (fire) {
            this.routes[route][event](arg);
        }
    }

    loadEvents() {
        this.fire('common');

        document.body.className.toLowerCase().split(/\s+/).map(camelcase)
            .forEach((className) => {
                this.fire(className);
                this.fire(className, 'finalize');
            });

        this.fire('common', 'finalize');
    }
}

export default Router;
