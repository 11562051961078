import 'jquery';

import './autoload/_bootstrap-datepicker.js'; import './autoload/_bootstrap-notify.js'; import './autoload/_bootstrap.js';
import '../ui/js/app';

import Router from './util/Router';
import common from './routes/common';
import membersLogin from './routes/login';
import editProfile from './routes/profile';

const routes = new Router({
    common,
    membersLogin,
    editProfile,
});

jQuery(document).ready(() => routes.loadEvents());
