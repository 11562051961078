export default {
    init() {
        this.addNewChild();
        this.initDatepicker();
        this.togglePregnantStatus();
    },
    togglePregnantStatus() {
        $('.js-pregnant-toggle').on('change', (e) => {
            let $reminder = $(e.currentTarget).closest('.form-group').next();
            if ($(e.currentTarget).is(':checked')) {
                $reminder.removeClass('d-none');
            } else {
                $reminder.addClass('d-none');
                $reminder.find('input').val('');
            }
        });
    },
    initDatepicker() {
        this._configDatePicker($('.datepicker'));
    },
    _configDatePicker($em) {
        $em.datepicker({
            language: 'hu',
            format: 'yyyy. MM dd.',
            autoclose: true,
            startDate: '1900-01-01'
        });
    },
    addNewChild() {
        let $addChildren = $('.js-add-children');
        const prototype = $addChildren.data('prototype');
        let $buttonWrapper = $addChildren.closest('.js-submit-profile');
        let counter = $addChildren.data('index');
        $addChildren.on('click', (e) => {
            let newCard = prototype;
            newCard = newCard.replace(/__name__/g, counter++);
            $(newCard).insertBefore($buttonWrapper);
            this._configDatePicker($buttonWrapper.prev().find('.datepicker'));
        });
        $('.js-edit-profile-form').on('click', '.icon-garbage', (e) => {
            $(e.currentTarget).closest('.card').remove();
        });
    },
}
